<template>
  <div>
    <h3 class="title">Confirme seu agendamento</h3>
    <section class="my-5">
      <property-value
        icon="person"
        property="Terapeuta selecionado"
        :value="selectedTherapist.therapistName"
      />
      <property-value
        icon="today"
        property="Data escolhida"
        :value="selectedDate | formatDate"
      />
      <property-value
        icon="alarm"
        property="Horário de ínicio"
        :value="selectedTime.startAt | formatTime"
      />
      <property-value
        icon="attach_money"
        property="Valor da sessão"
        :value="formatValue(selectedTherapist.sessionValue)"
      />
    </section>
    <section class="my-5 body-2">
      <h4 class="title mb-3">
        <v-icon color="warning">warning</v-icon>
        Instruções
      </h4>
      <p>- O horário escolhido já está de acordo com seu horário local.</p>
      <p>
        - Será necessário que o terapeuta confirme o agendamento. Quando
        confirmado, você receberá um e-mail de confirmação do seu agendamento.
      </p>
      <p>
        - Caso seja necessário, o terapeuta poderá cancelar esse agendamento e
        remarcar para o melhor dia e horário. Se isso acontecer, fique tranquilo
        pois você será avisado(a).
      </p>
      <p>
        - Você pode acompanhar o status dos seus agendamentos pelo menu
        "Agendamentos".
      </p>
    </section>
    <dialog-form-user-data />
    <stepper-buttons :hide-back-button="!!idFromWebsite" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StepConfirmation",
  components: {
    StepperButtons: () => import("./StepperButtons"),
    DialogFormUserData: () => import("./DialogFormUserData"),
  },
  props: {
    idFromWebsite: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("appointments/patient", [
      "selectedTherapist",
      "selectedDate",
      "selectedTime",
    ]),
  },
  methods: {
    formatValue(value) {
      return value ? `R$${value}` : "R$0.00";
    },
  },
};
</script>

<style scoped></style>
